import React, {useState, useEffect} from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Swal from "sweetalert2";

import Layout from "../../../components/layout"
import Navbar from "../../../components/navbar"
import Seo from "../../../components/seo"

import * as Services from "../../../services"

function AddProductorPage() {
    const [auth, setAuth] = useState(null)
    // const auth =

    useEffect(() => {
        setAuth(localStorage.getItem('auth'));
    }, [])

    let authUser = null;
    let user = null;

    if (auth) {
        authUser = JSON.parse(auth);
        const {user: authUsr} = authUser;
        user = authUsr;
    }

    const initialValues = {
        name: '',
        razonSocial: '',
        rfc: '',
        phone: '',
        email: '',
        datosInteres: '',
        status: '',
    };

    const sendProductor = async (values, { setSubmitting, resetForm }) => {
        if (auth) {
            authUser = JSON.parse(auth);
            const {user: authUsr} = authUser;
            user = authUsr;
        }
        setTimeout(() => {
            const body = {
                ...values,
                userID: user.id.toString()
            }
            Services.AddProductor(body).then(response => {
                console.log(response);
                Swal.fire({ html: response.message, icon: "success" });
                setSubmitting(false);
                resetForm();
            }).catch(error => {
                console.log(error);
                Swal.fire({ title: "Ha ocurrido un error", html: "Intenta más tarde", icon: "error" })
                setSubmitting(false);
            })
        }, 100)

    }

    return (
        <Layout>
            <Navbar />
            <div className="dashInfo">
                <div className="titleGeneral h-1/4">
                    <div className="font-bold text-white text-center pt-8 pb-12">
                        Agregar Productor
                    </div>
                </div>
                <div className="bg-white pt-6 px-6 overflow-x-auto h-3/4 rounded-t-3xl -mt-8">
                    <Formik
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            if (!values.name) {
                                errors.name = 'El nombre del productor es requerido';
                            }
                            if (!values.razonSocial) {
                                errors.razonSocial = 'La razón social es requerida';
                            }
                            if (!values.rfc) {
                                errors.rfc = 'El rfc es requerido';
                            }
                            if (!values.phone) {
                                errors.phone = 'El teléfono es requerido';
                            }
                            if (!values.email) {
                                errors.email = 'El correo electrónico es requerido';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'El correo electrónico es inválido';
                            }
                            return errors;
                        }}
                        onSubmit={sendProductor}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="mb-2">
                                    <label className="block text-green-gu text-sm font-medium mb-2" htmlFor="name">Nombre del productor</label>
                                    <Field type="text" id="name" name="name" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                    <ErrorMessage name="name" component="div" className="text-xs text-red-500" />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-green-gu text-sm font-medium mb-2" htmlFor="razon">Razón Social</label>
                                    <Field type="text" id="razon" name="razonSocial" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                    <ErrorMessage name="razonSocial" component="div" className="text-xs text-red-500" />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-green-gu text-sm font-medium mb-2" htmlFor="rfc">RFC</label>
                                    <Field type="text" id="rfc" name="rfc" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                    <ErrorMessage name="rfc" component="div" className="text-xs text-red-500" />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-green-gu text-sm font-medium mb-2" htmlFor="phone">Teléfono</label>
                                    <Field type="text" id="phone" name="phone" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                    <ErrorMessage name="phone" component="div" className="text-xs text-red-500" />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-green-gu text-sm font-medium mb-2" htmlFor="email">Correo Electrónico</label>
                                    <Field type="email" id="email" name="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                    <ErrorMessage name="email" component="div" className="text-xs text-red-500" />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-green-gu text-sm font-medium mb-2" htmlFor="status">Selecciona tipo</label>
                                    <Field as="select" name="status"
                                           className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                        <option value="">Selecciona una opción</option>
                                        <option value="Nuevo">Nuevo</option>
                                        <option value="Continuidad">Continuidad</option>
                                        <option value="Cliente">Cliente</option>
                                    </Field>
                                </div>
                                <div className="mb-2">
                                    <label className="block text-green-gu text-sm font-medium mb-2" htmlFor="datosInteres">Cantidad de hectáreas o datos de interés</label>
                                    <Field as="textarea"
                                           name="datosInteres"
                                           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    />
                                </div>
                                <Field type="hidden" name="userID" />
                                <button type="submit" className="inline-flex items-center justify-center p-3 w-full bg-green-light-gu rounded-full font-bold text-white focus:ring-2 focus:ring-green-light-gu focus:bg-green-gu" disabled={isSubmitting}>
                                    {isSubmitting && (
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                    stroke-width="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>)}  {!isSubmitting ? 'Guardar productor': 'Enviando'}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Layout>
    )
}

export const Head = () => <Seo title="Dashboard" />

export default AddProductorPage
